<template>
  <div class="content">
    <h1>lee.array.randomArrayObject(Number, Keys)</h1>
    <p class="cm-variable">随机生成数量为number的不重复数组对象</p>
    <h3>参数</h3>
    <ul>
      <li><code>Number</code><em>(Number)</em>:数量</li>
      <li><code>Keys</code><em>(Array)</em>:生成的数组对象的属性值</li>
    </ul>
    <h3>例子</h3>
    <div class="jscode" style="background: #f6f8fa">
      <div class="jscontent" v-highlight>
        <pre><code class="language-javascript">let myArr = lee.array.randomArrayObject(6, ['x','y'])
console.log(myArr)</code></pre>
      </div>
    </div>
  </div>
</template>
<script>
let lee = window.lee
let myArr = lee.array.randomArrayObject(6, ['x','y'])
console.log(myArr)
</script>
